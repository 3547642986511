import { LoadingOutlined } from "@ant-design/icons";
import {
  faAngleLeft,
  faBan,
  faBoxOpen,
  faCode,
  faHeart,
  faPenToSquare,
  faTag,
  faUsers,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, notification, Select, Skeleton, Spin, Tabs, Button } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import "../../css/controlRoom.scss";
import { getEventsBy_Id } from "../../redux/actions/event.action";
// import { useSearchParams } from "react-router-dom";
import numeral from "numeral";

import ReactHlsPlayer from "react-hls-player";
import { useNavigate } from "react-router-dom";
import { getChat, getChatLog } from "../../redux/actions/chat";
import useMediaQuery from "../../reUsable/useMediaQuery";
import ExternalRecordedChat from "./externalRecordedChat";
import ReactPlayer from "react-player";
import { endLiveExternalStream, getExternalEventsBy_Id } from "../../redux/actions/externalEvent.action";
import Swal from "sweetalert2";
import useWebSocket from 'react-use-websocket';
import RecordedShowChat from "../shows/recordedShowChat";
import Countdown from "antd/lib/statistic/Countdown";
const socketUrl = process.env.REACT_APP_SOCKET_URL

const { TabPane } = Tabs;

const menu = (
  <Menu
    // onClick={handleMenuClick}
    items={[
      {
        label: "Edit",
        key: "1",
        icon: <FontAwesomeIcon icon={faPenToSquare} />,
      },
      {
        label: "Embed",
        key: "2",
        icon: <FontAwesomeIcon icon={faCode} />,
      },
      {
        label: "Cancel",
        key: "3",
        icon: <FontAwesomeIcon icon={faBan} />,
      },
    ]}
  />
);
const handleButtonClick = (e) => {
  // message.info('Click on left button.');
  // console.log('click left button', e);
};

const { Option } = Select;
// const handleChange = (value) => {
//   
// };

function ExternalRecordedEvent() {
  const mediaHeight = useMediaQuery("(max-width: 1280px)");

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [top, setTop] = useState(15);
  const dispatch = useDispatch();
  const location = useLocation();
  const [spinner, setSpin] = useState(true);
  const [cameraPreview, setCameraPreview] = useState(false);
  const [startbroadcast, setStartBroadcast] = useState(false);
  const [dateTime, setDate] = useState("");
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState("");
  const [title, setTitle] = useState("");
  const [recorded, setRecorded] = useState("");
  const [liveData, setLiveData] = useState("");
  const [modal2Visible, settingsModal] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);
  const [mirror, setMirror] = useState(false);
  const [mute, setMute] = useState(false);
  const [error, setError] = useState("");
  const [endSession, setEndSession] = useState(false);
  const [endLiveLoading, setEndLiveLoading] = useState(false);
  const [settingPreview, setSettingPreview] = useState(false);
  const [videoDevices, setVideoDevices] = useState();
  const [audioDevices, setAudioDevices] = useState();
  const [audioOutputDevices, setAudioOutputDevices] = useState();
  const [screenView, setScreenView] = useState("landscape");
  const [mirrorVideo, setMirrorVideo] = useState(true);
  const [videoDeviceID, setVideoDeviceID] = useState("");
  const [audioMicrophoneID, setAudioMicrophoneID] = useState("");
  const [controlData, setControlData] = useState("");
  const [chat, setChat] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [height, setHeight] = useState(440);
  const [videoLoad, setVideoLoad] = useState(false);
  let [isBlocking, setIsBlocking] = useState(false);
  const [loading, setLoading] = useState(false);

  const [videoStartTime, setVideoStartTime] = useState("");
  const [currentTimestamp, setCurrentTimestamp] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);


  const didUnmount = useRef(false);
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      return didUnmount.current === false;
    },
    reconnectAttempts: 2,
    reconnectInterval: 3000,
  });
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);


  const history = useNavigate();
  const { getExternalEventById, getBroadcastClient, authenticate, validate } =
    useSelector((state) => {
      return state;
    });
  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;

  const chatRef = useRef();

  // usePrompt(
  //   "If you proceed Braodcast will be stop, Are you sure you want to proceed?",
  //   isBlocking,
  //   id
  // );

  useEffect(() => {
    if (id) {
      dispatch(getExternalEventsBy_Id(id)).then((res) => {
        if (res.payload.success) {
          setSpin(false);
          setEventEnded(res.payload?.message);
          setRecorded(res.payload?.message[0]?.event?.playback_url);
          setBanner(res.payload?.message[0]?.event?.banner);
          setDate(res.payload?.message[0]?.event?.start_date);
          setTitle(res.payload?.message[0]?.event?.title);

          setVideoStartTime(new Date(res?.payload?.message[0]?.event?.started_at).getTime());
          setCurrentTimestamp(new Date(res?.payload?.message[0]?.event?.started_at).getTime());

          setTimeout(() => {
            const resizeObserver = new ResizeObserver((event) => {
              setHeight(event[0].contentBoxSize[0].blockSize);
            });

            if (chatRef && chatRef?.current) {
              resizeObserver.observe(chatRef.current);
            }
          }, 500);
        }
      });
    }
  }, [chatRef, id]);

  // useEffect(() => {
  // 	const interval = setInterval(() => {

  // 		if (videoRef.current) {

  // 			const videoCurrentTime = videoRef?.current?.currentTime * 1000;
  // 			if (videoCurrentTime !== 0) {
  // 				setCurrentTimestamp(videoStartTime + videoCurrentTime);
  // 			}
  // 		}
  // 	}, 1000);

  // 	return () => clearInterval(interval);
  // }, [id]);

  const onSetting = (key) => {
    if (key === "audio") {
    } else if (key === "video") {
    }
  };

  const onScreenView = (e) => {
    setScreenView(e.target.value);
  };

  const onMirrorVideo = (e) => {
    setMirrorVideo(e.target.checked);
  };

  const handleVideoDevice = (value) => {
    setVideoDeviceID(value);
  };

  const handleAudioMicrophone = (value) => {
    setAudioMicrophoneID(value);
  };

  const onKeyChange = (key) => {
    if (key == "1" && id) {
      setChatLoading(true);
      dispatch(getChat(id)).then((res) => {
        if (res.payload.success) {
          setChatLoading(false);
          setChat(res.payload?.message?.data);
        } else {
          setChatLoading(false);
          notification.error({
            message: res.payload.data.message,
            className: "toast-error",
          });
        }
      });
    }
  };

  useEffect(() => {
    if (lastMessage && lastMessage?.data) {

      let newData = JSON.parse(lastMessage.data)

      if (newData?.event) {
        const match = newData.event.some(item => item.id == id);

        if (match) {
          setLoading(true);
          notification.success({
            message: "Your event has gone end",
            className: "toast-success",
          });
          setTimeout(() => {
            history({
              pathname: `/external-events?type=Live+Events`,
            });
          }, 1000);
        }
      }

      // console.log(newData);
      // const match = getExternalEvent?.payload?.message?.data.some(item => item.event_id === newData?.event?.[0]?.id);

      // if (match) {
      //   dispatch(getExternalEvents(limit, currentPage + 1, title, sortBy));
      // }
    }
  }, [lastMessage])

  function endLive() {
    // console.log(seletedItem);

    // setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To End This Live Event?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#052977",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);

        dispatch(endLiveExternalStream(id))
          .then(() => {
            setDeleteLoading(false);
            const endLiveMessage = {
              type: 'eventmessage',
              event: [{ id: getExternalEventById?.payload?.message[0]?.event?._id, title: getExternalEventById?.payload?.message[0]?.event?.title, brand: validate?.payload?.message?._id }],
              message: 'End External Event'
            };
            sendMessage(JSON.stringify(endLiveMessage));
            notification.success({
              message: "Your event has gone end",
              className: "toast-success",
            });
            setLoading(true);

            setTimeout(() => {
              history({
                pathname: `/external-events?type=Live+Events`,
              });
            }, 1000);
          })
          .catch((err) => {
            setDeleteLoading(false);
            notification.error({
              message: err.response.message,
              className: "toast-error",
            });
          });
      }
    });
  }

  const handleProgress = (state) => {
    // state.playedSeconds is the current play time in seconds
    // setCurrentTime(state.playedSeconds);
    const videoCurrentTime = state.playedSeconds * 1500;
    if (videoCurrentTime !== 0) {
      setCurrentTimestamp(videoStartTime + videoCurrentTime);
    }
  };

  const myPlayer = useMemo(() => {
    return (
      // <ReactHlsPlayer
      //   src={recorded}
      //   autoPlay={true}
      //   controls={true}
      //   loop={true}
      // />
      <ReactPlayer
        onProgress={handleProgress}
        playing
        // className="review_react_player"
        width={"100%"}
        height={"100%"}
        url={recorded}
        loop={true}
        controls
      />
    );
  }, [recorded]);
  if (!spinner) {
    return (
      <>
        {eventEnded.length === 0 ? (
          <div className="no-result-found event-ended">
            <div className="no-result-img">
              <img
                src={validate?.payload?.message?.profile_image_url}
                alt="searchEventImg"
              />
            </div>
            {/* <h3 className="no-event-hd"> Event has ended.</h3> */}
            <p className="event-para mb-20">Event has ended.</p>
            <Link to="/events" className="default-btn d-inline-flex ff-nunito">
              <span> Go to events</span>
            </Link>
          </div>
        ) : (
          // <div className="event-ended d-flex align-items-center justify-content-center">
          //   Event has ended
          // </div>

          <>
            <div className="control-room-top-area mb-2">
              <div className="control-top-box">
                <Link
                  to={
                    validate?.payload?.message?.account_type === "brand"
                      ? `/external-events?type=${location?.state?.pageName}`
                      : `/host-event?type=${location?.state?.pageName}`
                  }
                  className="prev-next-link ff-nunito"
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                  <span>Go back</span>
                </Link>
                {/* </a> */}
              </div>
            </div>

            <div className="brand_container_main container event-preview-box-main mb-20">
              {!getExternalEventById.loading ? (
                <div className="row">
                  <div className="col-md-2 col-lg-2 d-lg-flex align-items-center">
                    {/* <div className="event-banner" style={{ backgroundImage: `url(${getExternalEventById?.payload?.message[0]?.banner})` }} > */}
                    <div className="event-banner w-md-100">
                      <div className="event-box w-100 h-100 overflow-hidden">
                        <div className="event-banner-img">
                          <img
                            src={
                              getExternalEventById?.payload?.message[0]?.event?.banner
                            }
                            alt="banner"
                          />
                        </div>
                        <div>
                          <div className="schedule-badge">{getExternalEventById?.payload?.message[0]?.event?.event_status}</div>
                        </div>

                        {/* <div className="d-flex justify-content-end">
                  <div className="ribbon-small">
                    <FontAwesomeIcon icon={faFlask} />
                    <span>Event </span>
                  </div>
                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border border-left-0 align-items-center pl-xl-0">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Event</h4>
                      <h5
                        className="preview-date"
                        title={getExternalEventById?.payload?.message[0]?.event?.title}
                      >
                        {getExternalEventById?.payload?.message[0]?.event?.title
                          .length > 30
                          ? getExternalEventById?.payload?.message[0]?.event?.title.slice(
                            0,
                            30
                          ) + "..."
                          : getExternalEventById?.payload?.message[0]?.event?.title}
                      </h5>
                      {/* <h6 className="preview-time event-detail-txt">
                        <CalendarFilled /> LIVE-EVENT
                      </h6> */}
                      {/* <h6 className="preview-time">LIVE-EVENT</h6> */}
                      {/* <div className="event-detail-txt">
                        <CalendarFilled /> LIVE-EVENT
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center pl-xl-0">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Category</h4>
                      <h5
                        className="preview-date"
                        title={getExternalEventById?.payload?.message[0]?.event?.gen_category?.name}
                      >
                        {getExternalEventById?.payload?.message[0]?.event?.gen_category?.name}
                      </h5>
                      {/* <h6 className="preview-time event-detail-txt">
                        <CalendarFilled /> LIVE-EVENT
                      </h6> */}
                      {/* <h6 className="preview-time">LIVE-EVENT</h6> */}
                      {/* <div className="event-detail-txt">
                        <CalendarFilled /> LIVE-EVENT
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="col-md-2 col-lg-2 d-lg-flex  preview-data-border  align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Scheduled</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.start_date
                        )
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.start_date
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div> */}

                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">Event Date</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.started_at
                        )
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.started_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div>
                  {
                    getExternalEventById?.payload?.message[0]?.event?.duration !== 262800 &&
                    <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                      <div className="preview-date-main">
                        <h4 className="date-txt">Remaining Time</h4>
                        {/* <div className="schedule-badge schedule countdown-timer"> */}
                        <h5 className="preview-date">
                          <Countdown
                            value={moment(getExternalEventById?.payload?.message[0]?.event?.started_at).add(getExternalEventById?.payload?.message[0]?.event?.duration, 'minutes')}
                            format="mm : ss"
                          />
                        </h5>
                        {/* </div> */}
                        {/* <h5 className="preview-date">
                        {" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.started_at
                        )
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.started_at
                        ).format("hh:mm a")}
                      </h6> */}
                      </div>
                    </div>}
                  {/* 
                  <div className="col-md-2 col-lg-2 d-lg-flex preview-data-border align-items-center">
                    <div className="preview-date-main">
                      <h4 className="date-txt">End Date</h4>
                      <h5 className="preview-date">
                        {" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.ended_at
                        )
                          .utc()
                          .format("MM-DD-YYYY")}
                      </h5>
                      <h6 className="preview-time">
                        At{" "}
                        {moment(
                          getExternalEventById?.payload?.message[0]?.event?.ended_at
                        ).format("hh:mm a")}
                      </h6>
                    </div>
                  </div> */}




                  {/* <div className="col-lg-4 preview-data-border d-lg-flex align-items-center">
                    <div className="preview-date-main no-border-bottom">
                      <h4 className="date-txt notes">Details</h4>
                      <h6 className="preview-time notes-txt">
                        {getExternalEventById?.payload?.message[0]?.event?.notes !==
                          "undefined"
                          ? getExternalEventById?.payload?.message[0]?.event?.notes
                          : "-"}
                      </h6>
                    </div>
                  </div> */}
                </div>
              ) : (
                // <Spin className="m-auto d-table" indicator={antIcon} />
                <div className="event-detail-skeleton">
                  <Skeleton
                    avatar={{
                      size: "large",
                      shape: "square",
                    }}
                    active
                    paragraph={{
                      rows: 2,
                    }}
                  />
                </div>
              )}
            </div>

            <div className="brand_container_main container control-boxes-main">
              <div className="control-boxes-box" ref={chatRef}>
                <div className="recorded-main-grid">
                  <div className="recorded-left w-100">

                    {/* <div className="live-stats-main">
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-info "
                            icon={faUsers}
                          />
                        </div>
                        <div className="stats-value">
                          {getExternalEventById?.payload?.message[0]?.views
                            ? getExternalEventById?.payload?.message[0]?.views
                            : "0"}
                        </div>
                        <div className="stats-hd">Viewers</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-danger "
                            icon={faHeart}
                          />
                        </div>
                        <div className="stats-value">
                          {getExternalEventById?.payload?.message[0]?.likes
                            ? getExternalEventById?.payload?.message[0]?.likes
                            : "0"}
                        </div>
                        <div className="stats-hd">Likes</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon tag">
                          <FontAwesomeIcon className="" icon={faTag} />
                        </div>
                        <div className="stats-value">0</div>
                        <div className="stats-hd product-view-hd">
                          Product Views
                        </div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faBoxOpen}
                          />
                        </div>
                        <div className="stats-value">
                          <div className="stats-value">
                            {getExternalEventById?.payload?.message[0]?.event?.trans
                              ? getExternalEventById?.payload?.message[0]?.event?.trans[0]
                                ?.number_of_orders ? getExternalEventById?.payload?.message[0]?.event?.trans[0]
                                ?.number_of_orders : "0"
                              : "0"}
                          </div>
                        </div>
                        <div className="stats-hd">Orders</div>
                      </div>
                      <div className="live-stats-box">
                        <div className="stats-icon usd-txt">USD</div>
                        <div className="stats-value">
                          {getExternalEventById?.payload?.message[0]?.event?.trans
                            ? numeral(
                              getExternalEventById?.payload?.message[0]?.event?.trans[0]
                                ?.total_amount
                            ).format("0,0.0'")
                            : "0"}
                        </div>
                        <div className="stats-hd">Sales</div>
                      </div>
                    </div> */}

                    <div className="control-video-box-main recorded ">
                      {/* <div className="box-left">Data</div> */}
                      <div className="box-right">
                        <div className="video_preview " id="video_preview">
                          {myPlayer}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="recorded-right"></div> */}
                </div>
                <div className="d-flex justify-content-center mt-2 mb-2">
                  <Button
                    onClick={endLive}
                    className="ml-0 fw-normal btn-danger d-flex align-items-center justify-content-center"
                    // disabled={!cameraEnabled}
                    loading={deleteLoading}
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        className="event-icon mr-2"
                        icon={faVideo}
                      />
                    }
                  >
                    {" "}End Event
                  </Button>
                </div>
                {/* <div className="go-live-box d-flex">
                  <div className="d-flex align-items-center">
                  </div>
                </div> */}
              </div>
              <div
                className={`control-boxes-box tabs-control-room-main 
                ${mediaHeight ? "mediaheight" : ""}
                `}
                style={{ height: '490px' }}
              // style={{ height: height }}
              >
                <Tabs
                  className="tabs-control-room chat-tab-data"
                  defaultActiveKey="3"
                  onChange={onKeyChange}
                >
                  {/* <TabPane tab="Chat" key="1">
                    <div className="upcoming-box-main no-bg">
                      <div className="upcoming-img">
                        <img className="img-fluid" src={live1} alt="live1" />
                      </div>
                      <h4 className="hd-tab">Community Manager Login</h4>
                      <div className="seperate-line"></div>
                      <p className="upcoming-para">
                        {" "}
                        Login into the chat to interact with your audience!
                      </p>
                      <Form
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="inline"
                      >
                        <Form.Item>
                          <Input
                            className="login-input-chat"
                            size="large"
                            placeholder="Username"
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            className="default-btn width-auto d-flex m-auto  align-items-center justify-content-center m-0"
                            type="primary"
                          >
                            Login
                          </Button>
                        </Form.Item> */}

                  {/* <Link
                    to="#"
                    className="default-btn d-flex m-auto  align-items-center justify-content-center m-0">
                    <span className="nav-text d-none d-md-block">Login</span>
                  </Link> */}

                  {/* </Form>
                    </div>
                  </TabPane> */}

                  {/* <TabPane tab="Poll" key="2">
                    <h4 className="hd-tab">Upcomings</h4>
                    <div className="upcoming-box-main">
                      <div className="upcoming-img">
                        <img className="img-fluid" src={live2} alt="live2" />
                      </div>
                      <p className="upcoming-para">
                        {" "}
                        You didn't create poll for this event, yet.
                      </p>
                      <Link
                        to="#"
                        className="default-btn d-flex m-auto  align-items-center justify-content-center m-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="nav-text">Create</span>
                      </Link>
                    </div>
                  </TabPane> */}
                  <TabPane tab="Chat" key="1">
                    {/* <ExternalRecordedChat
                      chat={chat}
                      chatLoading={chatLoading}
                      height={height}
                      currentTimestamp={currentTimestamp}
                    /> */}
                    <RecordedShowChat
                      chat={chat}
                      chatLoading={chatLoading}
                      height={height}
                    />
                  </TabPane>
                  <TabPane tab="Products" key="3">
                    {/* <h4 className="hd-tab">Products Listing</h4> */}

                    <div
                      className="control-products-listing-main-wrapper scrollbar-style overflow-y-auto"
                      style={{ maxHeight: height - 90 }}
                    >
                      {getExternalEventById?.payload?.message[0]?.event?.products
                        .length ? (
                        getExternalEventById?.payload?.message[0]?.event?.products.map(
                          (item, i) => {
                            return (
                              <>
                                <div className="control-products-listing-main ">
                                  <div className="control-products-listing">
                                    <div className="control-product-image">
                                      <img
                                        src={item.mediaUrl}
                                        alt={item.title}
                                        className=""
                                      />
                                    </div>

                                    <div className="control-product-content">
                                      <div className="product-left w-100">
                                        <div
                                          className="control-product-title"
                                          title={item.title}
                                        >
                                          {item.title}
                                        </div>
                                        <div className="control-product-price justify-content-between d-flex">
                                          <span>${item.price}</span>
                                          <span><b>Stock: </b>{item?.variants?.reduce((acc, item) => {
                                            return (acc = acc + item.inventory_quantity);
                                          }, 0)}</span>
                                        </div>
                                      </div>
                                      <div className="product-right">
                                        <div className="product-quantity">
                                          {/* Qty: 150 */}{" "}
                                        </div>
                                        {/* <div className="control-product-copy">
                                      <button
                                        class="default-btn small-btn fw-normal"
                                        onClick={() =>
                                          productUrl(item.ProductUrl)
                                        }
                                      >
                                        Copy Url
                                      </button>
                                    </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <Link to="#" className="block-link"></Link> */}
                                </div>
                              </>
                            );
                          }
                        )
                      ) : (
                        <h5 className="text-center">No Product</h5>
                        // <Skeleton
                        //   avatar={{
                        //     size: "large",
                        //     shape: "circle",
                        //   }}
                        //   active
                        //   paragraph={{
                        //     rows: 2,
                        //   }}
                        // />
                      )}
                    </div>
                  </TabPane>
                  {/* <TabPane tab=" Announcement " key="4">
                    <h4 className="hd-tab">Upcomings</h4>
                    <div className="upcoming-box-main">
                      <div className="upcoming-img">
                        <img className="img-fluid" src={live2} alt="live2" />
                      </div>
                      <p className="upcoming-para">
                        {" "}
                        You didn't create annoucement for this event, yet.
                      </p>
                      <Link
                        to="#"
                        className="default-btn d-flex m-auto  align-items-center justify-content-center m-0"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="nav-text ">Create</span>
                      </Link>
                    </div>
                  </TabPane> */}
                </Tabs>
              </div>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <span className="spinclass loader-center">
        <Spin size="large" />
      </span>
    );
  }
}
export default ExternalRecordedEvent;
